import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const date = () => dayjs.utc();

const withUti = dayjs.utc;

export { withUti as dayjs };
export default date;
