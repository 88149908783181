import React from "react";
import {
  processingLogsAdded,
  processingStatusChange,
} from "reducers/processing-log";
import { LoadingStatus } from "reducers/processing-log/types";
import { AppDispatch } from "reducers/types";
import { selectProcessingLogById } from "selectors/processing-log";
import { useAppDispatch } from "./use-app-dispatch";
import { useSelector } from "./use-selector";

export const isIdle = (status?: LoadingStatus) => status === "idle";
export const isLoading = (status?: LoadingStatus) => status === "loading";
const hasError = (status?: LoadingStatus) => status === "failure";
const isSuccess = (status?: LoadingStatus) => status === "success";

export const useLoading = () => {
  const [status, setStatus] = React.useState<LoadingStatus>(LoadingStatus.Idle);

  const onIdle = () => {
    setStatus(LoadingStatus.Idle);
  };
  const onError = () => {
    setStatus(LoadingStatus.Failure);
  };
  const onLoading = () => {
    setStatus(LoadingStatus.Loading);
  };
  const onSuccess = () => {
    setStatus(LoadingStatus.Success);
  };

  return {
    status,
    isLoading: isLoading(status),
    isIdle: isIdle(status),
    hasError: hasError(status),
    isSuccess: isSuccess(status),
    onIdle,
    onError,
    onLoading,
    onSuccess,
  };
};

export const useProcessingLog = (id: string) => {
  const dispatch = useAppDispatch();
  const selectedLog = useSelector((state) =>
    selectProcessingLogById(state, id)
  );

  const { onIdle, onError, onLoading, onSuccess, addLog } =
    processingLogUtils(id)(dispatch);

  const status = selectedLog?.status;

  return {
    initiated: !!selectedLog,
    status,
    isLoading: isLoading(status),
    isIdle: isIdle(status),
    hasError: hasError(status),
    isSuccess: isSuccess(status),
    onIdle,
    onError,
    onLoading,
    onSuccess,
    addLog,
  };
};

export const processingLogUtils = (id: string) => {
  return (dispatch: AppDispatch) => {
    const addLog = () => {
      dispatch(processingLogsAdded([{ id, status: LoadingStatus.Loading }]));
    };
    const onIdle = () => {
      dispatch(processingStatusChange({ id, status: LoadingStatus.Idle }));
    };
    const onError = () => {
      dispatch(processingStatusChange({ id, status: LoadingStatus.Failure }));
    };
    const onLoading = () => {
      dispatch(processingStatusChange({ id, status: LoadingStatus.Loading }));
    };
    const onSuccess = () => {
      dispatch(processingStatusChange({ id, status: LoadingStatus.Success }));
    };
    return {
      addLog,
      onIdle,
      onError,
      onLoading,
      onSuccess,
    };
  };
};
