import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "reducers/types";
import {
  Features,
  isProPlanByPriceId,
  isUnlimitedPlanByPriceId,
  userHasGPT4Enabled,
} from "client-server-shared/utils/check-subscription";
import { dayjs } from "client-server-shared/utils/dayjs";

export const selectUser = (state: RootState) =>
  selectSession(state)?.user || null;

export const selectSession = (state: RootState) => state.user.session;

export const selectUserId = (state: RootState) => state.user.session?.user.id;

const adminUserIds = [
  "f2f98d5e-63c4-412b-92cd-f82429517add",
  "e5afbc74-c7dc-48ce-a032-91ae0bd010a8",
];
export const selectIsUserAdmin = createSelector(selectUserId, (userId) => {
  if (!userId) {
    return false;
  }
  return adminUserIds.includes(userId);
});

export const selectActivePlan = (state: RootState) => state.user.activePlan;
export const selectUserDetails = (state: RootState) => state.user.userDetails;

export const selectShowOnboarding = createSelector(
  selectUserDetails,
  (user) => {
    if (!user) {
      return false;
    }
    if (user.onboardingOption) {
      return false;
    }

    return true;
  }
);

export const selectUserWordsUsed = createSelector(
  selectUserDetails,
  (userDetails) => userDetails?.totalWordsUsed || 0
);

export const selectUserFreeWorkflows = createSelector(
  selectUserDetails,
  (userDetails) => userDetails?.workflows || 0
);

export const selectUserRemainingWords = createSelector(
  selectUserDetails,
  (userDetails) => {
    return userDetails?.totalCredits || 0;
  }
);

export const selectUserTotalWords = createSelector(
  selectUserRemainingWords,
  selectUserWordsUsed,
  (remainingWords: number, wordsUsed: number) => {
    return remainingWords + wordsUsed;
  }
);

export const selectUserFreeWorkflowsUsed = createSelector(
  selectUserDetails,
  (userDetails) => {
    return userDetails?.workflowsUsed || 0;
  }
);

export const selectUserPaidWords = createSelector(
  selectUserDetails,
  (userDetails) => userDetails?.paidCredits || 0
);

export const selectUserPaidWorkflows = createSelector(
  selectUserDetails,
  (userDetails) => userDetails?.paidWorkflows || 0
);

export const selectUserUsedPaidWorkflows = createSelector(
  selectUserDetails,
  (userDetails) => {
    return userDetails?.paidWorkflowsUsed || 0;
  }
);

export const selectUserTotalPaidWorkflows = createSelector(
  selectUserUsedPaidWorkflows,
  selectUserPaidWorkflows,
  (usedPaidWorkflows, paidWorkflows) => {
    return usedPaidWorkflows + paidWorkflows;
  }
);

export const selectUserAdvancedWords = createSelector(
  selectActivePlan,
  selectUserPaidWords,
  (activePlan, paidWords) => {
    return (activePlan?.advancedWords || 0) + (paidWords || 0);
  }
);

export const selectUserFreeAIImages = createSelector(
  selectUserDetails,
  (userDetails) => {
    return userDetails?.freeAiImages || 0;
  }
);

export const selectUserUsedFreeAIImages = createSelector(
  selectUserDetails,
  (userDetails) => {
    return userDetails?.freeAiImagesUsed || 0;
  }
);

const emptyFeatures: Features = {};

export const selectFeatures = createSelector(
  selectUserDetails,
  (userDetails) => {
    if (!userDetails) {
      return emptyFeatures;
    }
    return userDetails.features as Features;
  }
);

export const selectUserEmail = createSelector(selectSession, (session) => {
  return session?.user.email;
});

export const selectUserHasActiveSubscription = createSelector(
  selectActivePlan,
  selectUser,
  (activePlan, user) => !!(user && activePlan)
);

export const selectIsGPT4Enabled = createSelector(
  selectFeatures,
  selectUserHasActiveSubscription,
  (features, hasActiveSubscription) => {
    return hasActiveSubscription && userHasGPT4Enabled(features);
  }
);

export const selectUserWorkflowsGivenInPeriod = createSelector(
  selectActivePlan,
  (activePlan) => {
    return activePlan?.workflowsGivenInCurrentPeriod || 0;
  }
);

export const selectUserWorkflows = createSelector(
  selectActivePlan,
  (activePlan) => activePlan?.workflows
);

export const selectUserHasEnoughWorkflows = createSelector(
  selectUserWorkflows,
  selectUserFreeWorkflows,
  selectUserPaidWorkflows,
  (userWorkflows, freeWorkflows, paidWorkflows) => {
    return (userWorkflows || 0 + freeWorkflows || 0 + paidWorkflows || 0) > 0;
  }
);

export const selectUserWorkflowsUsedInPeriod = createSelector(
  selectActivePlan,
  (activePlan) => {
    return activePlan?.workflowsUsedInCurrentPeriod || 0;
  }
);

export const selectUserAIImagesGivenInPeriod = createSelector(
  selectActivePlan,
  (activePlan) => {
    return activePlan?.aiImagesGivenInCurrentPeriod || 0;
  }
);

export const selectUserAIImagesUsedInPeriod = createSelector(
  selectActivePlan,
  (activePlan) => {
    return activePlan?.aiImagesUsedInCurrentPeriod;
  }
);

export const selectUserInActiveProPlan = createSelector(
  selectActivePlan,
  selectUser,
  (activePlan, user) =>
    user && activePlan && isProPlanByPriceId(activePlan.priceId!)
);

export const selectUserInActiveUnlimitedPlan = createSelector(
  selectActivePlan,
  selectUser,
  (activePlan, user) =>
    user && activePlan && isUnlimitedPlanByPriceId(activePlan.priceId!)
);

export const selectUserRemainingAdvancedWords = createSelector(
  selectUserInActiveUnlimitedPlan,
  selectUserRemainingWords,
  selectUserAdvancedWords,
  (inUnlimitedPlan, renamingWords, advancedWords) => {
    if (inUnlimitedPlan) {
      return advancedWords;
    }
    return Math.round(renamingWords / 2);
  }
);

export const getUserCurrentPlanName = createSelector(
  selectUserInActiveUnlimitedPlan,
  selectUserInActiveProPlan,
  (isUnlimited, isProPlan) => {
    if (isUnlimited) {
      return "Unlimited";
    }
    if (isProPlan) {
      return "Creator";
    }
    return "Free";
  }
);

export const selectUserCanHavePaidTrial = createSelector(
  selectUserDetails,
  (userDetails) => {
    return userDetails?.canHavePaidTrial || false;
  }
);

export const selectUserInPaidTrial = createSelector(
  selectActivePlan,
  (activePlan) => {
    return activePlan?.status === "trialing";
  }
);

export const selectUserPaidTrialEndDate = createSelector(
  selectActivePlan,
  selectUserInPaidTrial,
  (activePlan, inPaidTrail) => {
    if (!inPaidTrail) {
      return null;
    }
    return activePlan?.trialEnd;
  }
);

export const selectNextBillingPeriod = createSelector(
  selectActivePlan,
  selectUserInPaidTrial,
  (activePlan, inPaidTrail) => {
    if (inPaidTrail) {
      return null;
    }
    if (activePlan?.cancelAtPeriodEnd) {
      return null;
    }
    return activePlan?.currentPeriodEnd;
  }
);

export const getUserCurrentPlanQuality = createSelector(
  getUserCurrentPlanName,
  (planName) => {
    if (planName === "Unlimited") {
      return "Ultimate";
    }
    if (planName === "Creator") {
      return "Premium";
    }
    return "High";
  }
);

export const selectIsFirstTimeChatWithFile = createSelector(
  selectUserDetails,
  (userDetails) => {
    return userDetails?.isFirstTimeChatWithFile;
  }
);

export const selectUserHasContentfulIntegrated = createSelector(
  selectUserDetails,
  (userDetails) => !!userDetails?.encrypted_contentful_access_token
);

export const selectUserHasWordPressIntegrated = createSelector(
  selectUserDetails,
  (userDetails) => !!userDetails?.encrypted_wordpress_access_token
);

const selectBaseNotification = (state: RootState) => state.user.notifications;

const emptyNotfication = [];

export const selectUserNotifications = createSelector(
  selectBaseNotification,
  (notifications) => {
    if (!notifications) return emptyNotfication;

    return notifications.filter((notification) => {
      const seen = notification.seen;
      const expired = notification.expires_at
        ? dayjs(notification.expires_at).isBefore(dayjs())
        : false;
      return !seen && !expired;
    });
  }
);
