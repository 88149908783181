export const cnUrl = "https://www.heyjunia.cn";
export const enUrl = "https://www.blogimagesai.com";

const siteConfig = {
  copyright: "YZplanet oy",
  title: "Blog Images AI - The #1 Image Generator for blog.",
  description:
    "Blog Images AI is the Best Image Generator for blog posts and articles! Create beautiful, theme-aligned images for your blogs, lower bounce rates, and retain visitors on your site longer. Ready to captivate your audience?",
  email: "contact@blogimagesai.com",
  logo: {
    url: "/logo.svg",
    alt: "Logo of blogimagesai.com",
  },
  whiteLogo: {
    url: "/logo-white.png",
    alt: "Logo of blogimagesai.com",
  },
  logoIcon: {
    url: "/logo-icon-only.png",
    alt: "Logo of blogimagesai.com",
  },
  logoWithBlackText: {
    url: "/logo-with-black-text.svg",
    alt: "Logo of blogimagesai.com",
  },
  favicon: "/favicon.png",
  openGraphImage: "/og.png",
  url: enUrl,
  locale: "en",
  name: "Blog Images AI",
  links: {
    twitter: "https://twitter.com/junia_ai",
    facebook: "https://www.facebook.com/profile.php?id=100091403777685",
    youtube: "https://youtube.com/junia.ai",
    linkedin: "https://linkedin.com/junia.ai",
  },
};

export { siteConfig };
