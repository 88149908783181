import {
  serverRoutes,
  templateServerRouteBySlug,
} from "client-server-shared/config/routes";
import { siteConfig } from "client-server-shared/config/siteConfig";
import { LogoIconOnly } from "components/logo";
import { NextLink } from "components/next-link";
import { Avatar } from "components/ui/avatar";
import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import { Container } from "components/ui/container";
import { List, ListItem, ListSubHeader } from "components/ui/list/List";
import { Typography } from "components/ui/Typography";
import { MainBackgroundWrapper } from "./shared";
import { MetaColorIcon } from "client-server-shared/icons/meta-color-icon";
import { TwitterColorIcon } from "client-server-shared/icons/twitter-color-icon";
import { trackClickOnSocialMediaLink } from "utils/tracking/common";
import { useTranslation } from "components/translate";

interface ListSectionProps {
  headerText: string;
  list: { text: string; to: string }[];
}

const useData = () => {
  const { t } = useTranslation("common");

  const productLists = [
    {
      text: t("Chat By Junia.ai"),
      to: serverRoutes.chat,
    },
    {
      text: t("Templates"),
      to: serverRoutes.templates,
    },
    {
      text: t("Junia Art"),
      to: serverRoutes.art,
    },
    {
      text: t("Image Restorer"),
      to: serverRoutes.imageRestore,
    },
    {
      text: t("Junia AI Article Editor"),
      to: serverRoutes.editor,
    },
    {
      text: t("Junia Chat vs ChatGPT"),
      to: serverRoutes.chatgpt,
    },
    {
      text: t("Junia Chat With Any Files"),
      to: serverRoutes.filechat,
    },
    {
      text: t("Pricing"),
      to: serverRoutes.pricing,
    },
    {
      text: t("Try for free"),
      to: serverRoutes.register,
    },
  ];
  const resourcesList = [
    {
      text: t("Guides"),
      to: serverRoutes.docs,
    },
    {
      text: t("Blogs"),
      to: serverRoutes.blog,
    },
    {
      text: t("Personalized Writing"),
      to: serverRoutes.styleMatch,
    },
    {
      text: t("Product Description"),
      to: templateServerRouteBySlug("product-description"),
    },
    {
      text: t("Customer Testimonial"),
      to: templateServerRouteBySlug("customer-testimonial"),
    },
    {
      text: t("Competitor Analysis"),
      to: templateServerRouteBySlug("competitor-analysis"),
    },
    {
      text: t("Dating App Message Reply"),
      to: templateServerRouteBySlug("dating-app-replyer"),
    },
    {
      text: t("AI Music Generator"),
      to: serverRoutes.musicGen,
    },
    {
      text: t("Citation Generator"),
      to: serverRoutes.citation,
    },
  ];

  const supportList = [
    {
      text: t("Terms"),
      to: serverRoutes.terms,
    },
    {
      text: t("Privacy Policy"),
      to: serverRoutes.privacyPolicy,
    },
    {
      text: t("Contact us"),
      to: serverRoutes.contact, // "mailto:contact@junia.ai", // serverRoutes.contact,
    },
  ];
  return { productLists, resourcesList, supportList };
};

const ListSection = ({ headerText, list }: ListSectionProps) => {
  return (
    <List
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      subheader={
        <ListSubHeader
          disableGutters
          sx={{
            background: "none",
          }}
        >
          <Typography variant="body1" fontWeight={600} color="text.white">
            {headerText}
          </Typography>
        </ListSubHeader>
      }
    >
      {list.map((item) => {
        return (
          <ListItem
            key={item.text}
            disableGutters
            className="with-hover-underline-effect-a"
            sx={{
              paddingTop: "4px",
              paddingBottom: "4px",
              letterSpacing: ".5px",
            }}
          >
            <Typography
              component={NextLink}
              sx={{
                textDecoration: "none",
                fontSize: "14px",
                fontWeight: 300,
              }}
              href={item.to}
              color="text.white"
            >
              {item.text}
            </Typography>
          </ListItem>
        );
      })}
    </List>
  );
};

const FooterBottom = () => {
  return (
    <Box
      sameLine
      alignCenter
      marginTop={48}
      sx={{
        justifyContent: "center",
        columnGap: "48px",
      }}
    >
      <Typography color="text.white">@ 2023 BlogImagesAI.com</Typography>
      <Box>
        {/*
          <IconButton
          component="a"
          rel="noopener noreferrer"
          href={siteConfig.links.twitter}
          target="__blank"
          onClick={() => {
            trackClickOnSocialMediaLink({
              type: "twitter",
            });
          }}
          aria-label="Twitter"
        >
          <Avatar>
            <TwitterColorIcon />
          </Avatar>
        </IconButton>
          */}
        {/*
                  <IconButton
          component="a"
          href={siteConfig.links.linkedin}
          target="__blank"
        >
          <Avatar>
            <LinkedinColorIcon />
          </Avatar>
        </IconButton>
        <IconButton
          component="a"
          href={siteConfig.links.youtube}
          target="__blank"
        >
          <Avatar>
            <YoutubeColorIcon />
          </Avatar>
        </IconButton>
          */}
      </Box>
    </Box>
  );
};

const Footer = () => {
  const { productLists, resourcesList, supportList } = useData();
  const { t } = useTranslation("common");
  return (
    <MainBackgroundWrapper component="footer">
      <Container
        maxWidth="xl"
        sx={{
          padding: {
            xs: "80px 30px",
            sm: "80px",
            md: "80px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            rowGap: "24px",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexBasis: "15%",
              rowGap: "24px",
              width: "100%",
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "initial",
              },
            }}
          >
            <LogoIconOnly width={65} />
          </Box>
          <Box
            component="section"
            sx={{
              display: "flex",
              flexGrow: 1,
              flexWrap: "wrap",
              rowGap: "48px",
              columnGap: "12px",
              justifyContent: {
                xs: "space-between",
                sm: "space-between",
                md: "space-between",
                lg: "space-between",
                xl: "space-between",
              },
            }}
          >
            <ListSection headerText={t("Support")} list={supportList} />
            <FooterBottom />
          </Box>
        </Box>
      </Container>
    </MainBackgroundWrapper>
  );
};

export default Footer;
