import { Box } from "components/ui/box";
import { useRouter } from "next/router";
import {
  isLoginRoute,
  isValidServerRoute,
} from "client-server-shared/utils/get-url";
import Footer from "./footer";
import Header from "./common-header";
import React from "react";
import dynamic from "next/dynamic";
import { serverRoutes } from "client-server-shared/config/routes";
import { serverRoutesConfig } from "client-server-shared/config/routes";

const InnerWrapper = React.memo(
  ({ children }: { children: React.ReactNode }) => {
    const router = useRouter();

    return (
      <Box>
        {/*
         <PopupBanner />
        */}
        <Header />
        <Box component="main">{children}</Box>

        <Footer />
      </Box>
    );
  }
);

const NonProtectedLayout = React.memo(
  ({ children }: { children: React.ReactNode }) => {
    const router = useRouter();

    if (!isValidServerRoute(router.pathname) || isLoginRoute(router.pathname)) {
      return <>{children}</>;
    }
    return <InnerWrapper>{children}</InnerWrapper>;
  }
);

export default NonProtectedLayout;
