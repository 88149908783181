import { siteConfig } from "client-server-shared/config/siteConfig";
import axios from "axios";
import { LOCALE_HEADER } from "client-server-shared/get-locale-header";
import {
  API_VERSION_HEADER,
  getClientApiVersion,
} from "client-server-shared/get-client-api-version";
import { onClientAuthExpired } from "./auth";
import {
  getRequestErrorObject,
  isTooManyRequestError,
} from "client-server-shared/utils/error-parsing";
import { onTooManyRequest } from "./rate-limit";

export const apiClient = axios.create({
  withCredentials: true,
});

apiClient.defaults.headers.common[API_VERSION_HEADER] = getClientApiVersion();
apiClient.defaults.headers.common[LOCALE_HEADER] = siteConfig.locale;

apiClient.interceptors.request.use(function (config) {
  // Do something before request is sent
  return config;
});

apiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const status = error.response ? error.response.status : undefined;
    if (status === 401) {
      onClientAuthExpired();
    }
    if (status === 429) {
      const json = getRequestErrorObject(error);
      if (isTooManyRequestError(json)) {
        onTooManyRequest();
      }
    }
    return Promise.reject(error);
  }
);

const createFetchClient = () => {
  const defaultFetch = (...args: Parameters<typeof fetch>) =>
    fetch(...args).then(async (response) => {
      if (response.status === 401) {
        onClientAuthExpired();
      }
      if (response.status === 429) {
        const response2 = response.clone();

        const json = await response2.json();
        if (isTooManyRequestError(json)) {
          onTooManyRequest();
        }
      }
      return response;
    });

  return {
    post: (url: RequestInfo | URL, options: RequestInit = {}) =>
      defaultFetch(url, {
        ...options,
        method: "POST",
        headers: {
          ...(options.headers || {}),
          [API_VERSION_HEADER]: getClientApiVersion(),
        },
      }),
  };
};

export const fetchClient = createFetchClient();
