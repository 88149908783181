import React from "react";
import { Typography, TypographyProps } from "./ui/Typography";

export const InlineSpan = React.forwardRef(
  (
    { children, inherit, ...rest }: TypographyProps & { inherit?: boolean },
    ref
  ) => {
    return (
      <Typography
        ref={ref}
        component="span"
        {...rest}
        sx={{
          ...(inherit
            ? {
                color: "inherit",
                lineHeight: "inherit",
                fontSize: "inherit",
                fontWeight: "inherit",
              }
            : {}),
          ...(rest.sx || {}),
        }}
      >
        {children}
      </Typography>
    );
  }
);

export const InlineLinkSpan = React.forwardRef(
  (
    {
      children,
      href,
      inherit,
      ...rest
    }: TypographyProps & { href: string; inherit?: boolean },
    ref
  ) => {
    return (
      <InlineSpan
        ref={ref}
        component="span"
        {...rest}
        sx={{
          ...(inherit
            ? {
                color: "inherit",
                lineHeight: "inherit",
                fontSize: "inherit",
                fontWeight: "inherit",
              }
            : {}),
          ...(rest.sx || {}),
        }}
      >
        <InlineSpan
          component="a"
          href={href}
          target="_blank"
          inherit={inherit}
          sx={{
            fontWeight: 600,
            color: "#911ba4",
          }}
        >
          {children}
        </InlineSpan>
      </InlineSpan>
    );
  }
);

export const HighlightedSpan = React.forwardRef(
  (
    { children, inherit, ...rest }: TypographyProps & { inherit?: boolean },
    ref
  ) => {
    return (
      <InlineSpan
        ref={ref}
        component="span"
        {...rest}
        sx={{
          ...(inherit
            ? {
                color: "inherit",
                lineHeight: "inherit",
                fontSize: "inherit",
                fontWeight: "inherit",
              }
            : {}),
          ...(rest.sx || {}),
        }}
      >
        <InlineSpan
          component="strong"
          inherit={inherit}
          sx={{
            fontWeight: 600,
            color: "#911ba4",
            ...(rest.sx || {}),
          }}
        >
          {children}
        </InlineSpan>
      </InlineSpan>
    );
  }
);

export const Span = ({
  children,
  ...rest
}: React.HTMLProps<HTMLSpanElement>) => {
  return <span {...rest}>{children}</span>;
};
