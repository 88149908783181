import { siteConfig } from "client-server-shared/config/siteConfig";
import Image from "next/image";
import React from "react";

import defaultLogo from "public/logo.svg";
import logoWithBlackText from "public/logo-with-black-text.svg";
import whiteLogo from "public/logo-white.png";
import logoOnly from "public/logo-icon-only.png";

export const Logo = () => {
  return (
    <Image
      height={35}
      width={100}
      priority
      src={defaultLogo}
      alt={siteConfig.logo.alt}
    />
  );
};

export const LogoWithBlackText = ({ height = 40, width = 132 }) => {
  return (
    <Image
      height={height}
      width={width}
      priority
      src={logoWithBlackText}
      alt={siteConfig.logoWithBlackText.alt}
    />
  );
};

export const WhiteLogo = ({ height = 40, width = 132 }) => {
  return (
    <Image
      height={height}
      width={width}
      priority
      src={whiteLogo}
      style={{
        objectFit: "contain",
        width: "100%",
      }}
      alt={siteConfig.whiteLogo.alt}
    />
  );
};

export const LogoIconOnly = ({
  className,
  ...rest
}: {
  className?: string;
  height?: number;
  width?: number;
}) => {
  return (
    <Image
      height={50}
      width={77}
      priority
      className={className}
      src={logoOnly}
      alt={siteConfig.logoIcon.alt}
      {...rest}
      style={{
        objectFit: "contain",
        minHeight: rest.height || 50,
        minWidth: rest.width || 77,
        ...(rest.style || {}),
      }}
    />
  );
};
