const pricesEnv = {
  dev: {
    proMonthly: ["price_1NmKF6BbfgqwjqSr0cGScqil"],
    proMonthlyLegacy: ["price_1NRFvDBbfgqwjqSrk1FUtzQg"],
    proYearly: ["price_1NmKFPBbfgqwjqSr4fyktUnS"],
    proYearlyLegacy: ["price_1NRFvDBbfgqwjqSrp1ZInCWJ"],
    unlimitedMonthly: ["price_1NmL98BbfgqwjqSrw7g9kwMK"],
    unlimitedMonthlyLegacy: [
      "price_1NRFWbBbfgqwjqSrLS04L4DJ",
      "price_1MqP1tBbfgqwjqSrN06SpKXE",
    ],
    unlimitedYearly: ["price_1NmLCOBbfgqwjqSr1JqlaBZF"],
    unlimitedYearlyLegacy: [
      "price_1NRFWbBbfgqwjqSrFMptpDyq",
      "price_1MqP1tBbfgqwjqSrbAt2Ph8I",
    ],
    credits: ["price_1N33zBBbfgqwjqSrW40MhxUh"],
    workflows: ["price_1NjgQ1BbfgqwjqSrj99GPHUi"],
    trialFee: ["price_1NaLt5BbfgqwjqSr0SHD1fzW"],
  },
  prod: {
    proMonthly: ["price_1NmKBSBbfgqwjqSrOXHiF84U"],
    proMonthlyLegacy: ["price_1NSKVwBbfgqwjqSrMRO4CGmr"],
    proYearly: ["price_1NmKCyBbfgqwjqSrMmqBIYLO"],
    proYearlyLegacy: ["price_1NSKVwBbfgqwjqSrNkzI9ld8"],
    unlimitedMonthly: ["price_1NmLNZBbfgqwjqSrnt0h2Gp2"],
    unlimitedMonthlyLegacy: [
      "price_1NSKVdBbfgqwjqSrj0gJPb3Q",
      "price_1MriCuBbfgqwjqSrhwhUV1aq",
    ],
    unlimitedYearly: ["price_1NmLOTBbfgqwjqSrv4ExUYEG"],
    unlimitedYearlyLegacy: [
      "price_1NSKVdBbfgqwjqSrQsZAwRf9",
      "price_1MriCuBbfgqwjqSrJtvw9XaV",
    ],
    workflows: ["price_1NjiPPBbfgqwjqSr4MHVX6yz"],
    credits: ["price_1N33xzBbfgqwjqSr1bwafoV9"],
    trialFee: ["price_1NangWBbfgqwjqSrr4kwUQ7i"],
  },
};

interface ById {
  proMonthly: string[];
  proYearly: string[];
  proYearlyLegacy: string[];
  proMonthlyLegacy: string[];
  unlimitedMonthly: string[];
  unlimitedYearly: string[];
  unlimitedMonthlyLegacy: string[];
  unlimitedYearlyLegacy: string[];
  credits: string[];
  workflows: string[];
  trialFee: string[];
}

export const pricesById: ById = pricesEnv[process.env.NEXT_PUBLIC_ENV_NAME];
